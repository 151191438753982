<template>
  <div>
    <div v-if="(!loading && propertyCharmSubscribed) || level=='corporate' ">
      <template v-if="level=='property' || level=='corporate' ">
        <hb-form label="Voicemail" :description="voiceMailDescription()" required>
          <div class="d-flex mt-n2">
            <v-col cols="12" class="py-1">
              <v-textarea
                :disabled="!hasPermission('configure_charm_settings')"
                v-validate.disable="'required|min:1'"
                data-vv-name="text"
                @input="updateError"
                :error="hasError"
                class="ma-4 expand-width"
                auto-grow
                v-model="text"
                rows="2"
                label="Enter voicemail message"
              ></v-textarea>
            </v-col>
          </div>
          <span class="d-flex align-self-center ml-2 mt-2">Select gender of automated voice</span>
          <div class="ma-4">
            <hb-radio-group v-model="selectedVoice" row :disabled="!hasPermission('configure_charm_settings')">
              <hb-radio label="Female" value="2" />
              <hb-radio label="Male" value="1" />
            </hb-radio-group>
          </div>

          <div class="charm-ivr-info-card">
            <v-row class="ma-0 pa-0" align="center">
              <v-col cols="auto" class="d-flex justify-center ma-0 pa-0">
                <v-icon color="#3D8FFF" medium>mdi-information</v-icon>
              </v-col>
              <v-col class="pl-1 ma-0 pa-0">
                To preview the voicemail, please save the message setting and initiate a test call to your property.              
              </v-col>
            </v-row>
          </div>
          
<!-- 
          <v-card class="silver-background" elevation="3" :disabled="!hasPermission('configure_charm_settings')">
            <v-card-title class="d-flex align-center non-bold">
              <hb-btn
                icon
                active-by-default
                always-active
                active-background-color="#808080"
                color="#ffffff"
                active-color="#ffffff"
                hover-color="#ffffff"
                hover-opacity
                @click="toggleSpeech"
                right
                :tooltip="checkPausePlay"
                :disabled="isDisabled"
              >
                mdi-play-pause
              </hb-btn>
              <div class="sentences">
                <div>Preview the voice mail message.</div>
                <p v-if="isPlaying">{{ timeRemaining }}/{{totalTime}} seconds</p>
              </div>
            </v-card-title>
          </v-card> -->
          <div class="ma-4">
            <hb-btn class="mr-4" @click="save" color="primary" :disabled="!hasPermission('configure_charm_settings')">Save</hb-btn>
            <hb-btn class="mr-4" @click="cancelButton()" color="primary" :disabled="!hasPermission('configure_charm_settings')">Cancel</hb-btn>
          </div>
        </hb-form>
        <charmCorporate v-if="corporateUpdate" @close="updateCorporate" @error="setError" @success="setSuccess" :voicemailData="voicemailData()"></charmCorporate>
      </template>
      
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import charmCorporate from "./charmCorporate.vue";
import api from "../../../assets/api.js";
import { notificationMixin } from '../../../mixins/notificationMixin.js';

export default {
  name: "charm-settings",
  mixins: [notificationMixin],
  props: {
    level: {
      type: String,
      default: "",
    },
    isPropertyLevel: {
      type: Boolean,
      default: false,
    },
    property: {
      type: String,
      default: "",
    },
    documents: {
      type: Array,
      default: () => [],
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkPausePlay: "Play",
      hasError: false,
      propertyCharmSubscribed: false,
      form_panels: [0],
      corporateUpdate: false,
      error: false,
      errorDescription: "",
      text: "",
      selectedVoice: "2",
      isPlaying: false,
      timeRemaining: 0,
      speechUtterance: null,
      speechSynthesis: window.speechSynthesis,
      timer: null,
      totalTime: 0,
      loading: true,
      deliveryMethods: [],
    };
  },
  components: {
    charmCorporate,
  },
  methods: {
    voiceMailDescription() {
      if (this.level == 'corporate') {
        return "Type out the voicemail you would like to hear at this company";
      } else {
        return "Type out the voicemail you would like to hear at this property";
      }
    },
    async cancelButton() {
      if (this.level == 'corporate') {
        this.text = "";
        this.selectedVoice = "2";
      } else {
        await this.fetchVoicemailData();
      }
    },
    updateError() {
      this.hasError = this.errors.collect('text').length > 0 && this.text === '';
    },
    async fetchVoicemailData() {
      this.loading = true;
      let propertyGds = this.properties.filter((ele) => {
        return ele.id == this.property;
      });
      await api.get(this.$app, api.CHARM_VOICEMAIL, { "facility": propertyGds[0].gds_id, "property_id": this.property })
        .then((data) => {
          this.text = data.voicemail_text ? data.voicemail_text.trim().replace(/\s+/g, ' ').replace(/\s+(?=[.,?!])/g, '') : "";
          this.selectedVoice = data.voice_type ? data.voice_type : " ";
          this.propertyCharmSubscribed = true;
        })
        .catch((err) => {
          this.text = "";
          this.selectedVoice = "2";
          this.propertyCharmSubscribed = true;
          this.$emit("setSnackBar", { type: "error", message: "Could not fetch voicemail for the property" });
          if (err == 'Property not subscribed for Charm') {
            this.propertyCharmSubscribed = false;
            this.$emit("setSnackBar", { type: "error", message: err });
          }
          console.log(err);
        });
      this.loading = false;
    },
    voicemailData() {
      return {
        text: this.text,
        voiceType: this.selectedVoice
      };
    },
    setError(err) {
      this.$emit("setSnackBar", { type: "error", message: err });
    },
    setSuccess(data) {
      this.$emit("setSnackBar", { type: "success", message: data });
    },
    updateCorporate() {
      this.corporateUpdate = false;
    },
    async save() {
      if (this.text === '') {
        this.hasError = true;
        this.showMessageNotification({
          type: 'error',
          description: 'Please enter voice mail message',
          timeout: 3000
        });
        return;
      }
      let propertyGds = this.properties.filter((ele) => {
        return ele.id == this.property;
      });
      if (this.level == 'corporate') {
        this.corporateUpdate = true;
      } else {
        api.post(this, api.CHARM_VOICEMAIL, { "property_id": this.property, "facility": propertyGds[0].gds_id, "voicemail_text": this.text, "voice_type": this.selectedVoice })
          .then((ele) => {
            this.$emit("setSnackBar", { type: "success", message: "Voice mail updated successfully" });
          })
          .catch((err) => {
            console.log(err);
            this.$emit("setSnackBar", { type: "error", message: "Something went wrong" });
          });
      }
    },
    toggleSpeech() {
      if (!this.isPlaying) {
        this.startSpeech();
        this.checkPausePlay = "Pause";
      } else {
        this.pauseSpeech();
        this.checkPausePlay = "Play";
      }
    },
    startSpeech() {
      this.isPlaying = true;
      this.timeRemaining = this.calculateDuration(this.text) == 1 ? this.calculateDuration(this.text) + 1 : this.calculateDuration(this.text);
      this.speechUtterance = new SpeechSynthesisUtterance(this.text);
      this.speechUtterance.voice = this.getVoice();
      this.speechSynthesis.speak(this.speechUtterance);
      this.timer = setInterval(() => {
        this.timeRemaining--;
        if (this.timeRemaining <= 0) {
          this.pauseSpeech();
        }
      }, 1000);
    },
    pauseSpeech() {
      this.isPlaying = false;
      clearInterval(this.timer);
      this.speechSynthesis.cancel();
      this.timeRemaining = 0;
      this.checkPausePlay = "Play";
    },
    getVoice() {
      const voices = this.speechSynthesis.getVoices();
      let voice1;
      if (this.selectedVoice == '1') {
        voice1 = 'Microsoft David - English (United States)';
      } else {
        voice1 = 'Microsoft Zira - English (United States)';
      }
      return voices.find(voice => voice.name.includes(voice1));
    },
    calculateDuration(text) {
      if (text.trim().length == 0) {
        return 0;
      } else {
        const words = text.trim().split(/\s+/).length;
        const durationInMinutes = words / 150;
        return Math.ceil(durationInMinutes * 60);
      }
    },
    showNotification(data) {
      this.$emit("setSnackBar", {
        type: data.type,
        message: data.message,
        errors: data.errors,
      });
    }
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      hasPermission: 'authenticationStore/rolePermission'
    }),
    isDisabled() {
      return this.textEmpty || !this.hasPermission('configure_charm_settings');
    },
    textEmpty() {
      return this.text.trim() === "";
    },
  },
  watch: {
    level: {
      async handler() {
        this.loading = true;
        this.text = "";
        this.selectedVoice = "2";
        this.updateError();
      },
    },
    property: {
      async handler(val) {
        this.hasError = false;
        this.$validator.reset();
        if (val) {
          await this.fetchVoicemailData();
        }
      },
      immediate: true,
    },
    text: {
      handler() {
        this.totalTime = this.calculateDuration(this.text) == 1 ? this.calculateDuration(this.text) + 1 : this.calculateDuration(this.text);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.hb-text-light.rent-settings-box-field-description {
  font-size: 11px;
  line-height: 13px;
}
.pink-box {
  background-color: rgb(252, 217, 223) !important;
  max-width: 400px;
  width: auto;
  overflow: hidden; 
}
.pink-background {
  background-color: rgb(252, 217, 223) !important;
  max-width: 400px;
  width: auto;
  overflow: hidden; 
}

.silver-background {
  background-color: #F4F6F8 !important; 
  max-width: 400px;
  width: auto;
  overflow: hidden; 
}
.sentences {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-left: 32px;
}
.custom-label-text {
  font-size: 5px; 
}
.expand-width {
  width: 100%;
  min-width: 200px;
}
.charm-ivr-info-card{
  border-radius: 4px;  
  border: 1px solid #3D8FFF;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #E7F1FF;
  width: 100%;

}
</style>
